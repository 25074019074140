import { AxiosResponse } from "axios";
import ReactGA from "react-ga4";
import { CategoryType } from "../constants/ga";

interface RequestFinallyProps {
  result: AxiosResponse | null;
}

const handleRequestFinally = (props: RequestFinallyProps) => {
  const { result } = props;
  if (result === null) return;
  ReactGA.event({
    category: CategoryType.Axios,
    action: result.config.url ?? "",
    value: result.status,
    nonInteraction: true,
  });
};

export default handleRequestFinally;
