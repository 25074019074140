import ReactGA from "react-ga4";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { BrowserRouter, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Layouts from "../src/Layouts";
import ErrorBoundary from "./utils/customProvider/ErrorBoundary";

console.log(process.env.REACT_APP_app_env);
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID ?? "", {
  testMode: process.env.REACT_APP_app_env !== "production",
});
// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID ?? "", {
//   testMode: false,
// });

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 * attemptIndex, 30000),
    },
    mutations: {
      cacheTime: 0,
    },
  },
});

const App = () => {
  console.log("rendered");

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary>
          {/* <CssBaseline /> */}
          <Layouts />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
